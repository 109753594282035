<template>
  <div>
    <v-row>
      <v-col cols="12">
        <b-input
          v-model="contractor.name"
          :error-messages="getErrorMessages(v$.name)"
          hide-details
          label="Повна назва"
          placeholder="Введіть текст"
          @blur="v$.name.$touch()"></b-input>
      </v-col>
      <v-col cols="12">
        <b-input
          v-model="contractor.shortName"
          :error-messages="getErrorMessages(v$.shortName)"
          hide-details
          label="Коротка назва"
          placeholder="Введіть текст"
          @blur="v$.shortName.$touch()"></b-input>
      </v-col>
      <v-col cols="6">
        <b-input
          v-model="contractor.code"
          :error-messages="getErrorMessages(v$.code)"
          :mask="'#'.repeat(10)"
          hide-details
          label="ЄДРПОУ/ІПН"
          placeholder="Введіть текст"
          @blur="v$.code.$touch()"></b-input>
      </v-col>
      <v-col cols="6">
        <b-select
          v-model="contractor.typeId"
          :error-messages="getErrorMessages(v$.typeId)"
          :items="$directory.get('contractorTypes')"
          :loading="$loading.isLoading('contractorTypes')"
          hide-details
          label="Тип"
          placeholder="Оберіть тип"
          @blur="v$.typeId.$touch()"
          @update:model-value="
            () => {
              contractor.stateRegistrationNumber = null
              contractor.stateRegistrationDate = null
            }
          "></b-select>
      </v-col>
    </v-row>

    <v-scroll-x-transition>
      <v-row v-show="contractor.typeId === 2">
        <v-col cols="6">
          <b-input
            v-model="contractor.stateRegistrationNumber"
            :mask="'#'.repeat(20)"
            hide-details
            label="Номер реєстрації"
            placeholder="Введіть номер"></b-input>
        </v-col>
        <v-col cols="6">
          <b-input-date
            v-model="contractor.stateRegistrationDate"
            hide-details
            label="Дата реєстрації"></b-input-date>
        </v-col>
      </v-row>
    </v-scroll-x-transition>

    <v-row>
      <v-col cols="12">
        <b-select
          v-model="contractor.directorId"
          :items="$directory.get('contractorList').filter(c => c.typeId === 1)"
          :loading="$loading.isLoading('contractorList')"
          hide-details
          label="Директор"
          placeholder="Оберіть зі списку"
          @focus="$directory.fill('contractorList')"></b-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { urlContractorCreate } from '@/utils/urls.js'
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import axios from '@/plugins/axios'
import { getErrorMessages } from 'best-modules/utils'
import { fillDirectory, pushDirectoryItem } from '@/directory/index.ts'
import { BInputDate } from 'best-modules/components'

export default {
  name: 'Contractor',
  components: { BInputDate },
  methods: { getErrorMessages },
  setup() {
    fillDirectory('contractorTypes')
    const contractor = reactive({
      name: null,
      code: null,
      typeId: null,
      shortName: null,
      stateRegistrationNumber: null,
      stateRegistrationDate: null,
      directorId: null,
    })
    const rules = {
      name: { required },
      code: { required },
      typeId: { required },
      shortName: { required },
    }

    const v$ = useVuelidate(rules, contractor)

    const submit = () => {
      return axios.post(urlContractorCreate(), contractor).then(res => {
        pushDirectoryItem('contractorList', res)

        return res
      })
    }

    return { submit, v$, contractor }
  },
}
</script>
