<template>
  <div>
    <b-section-loader
      v-if="$loading.isLoading(['getAudit', 'updateAudit'])"></b-section-loader>
    <div v-else class="mt-3">
      <v-row>
        <v-col :cols="12" :md="4">
          <audit-sidebar @complete-audit="completeAudit"></audit-sidebar>
        </v-col>
        <v-col
          :class="!$vuetify.display.smAndUp ? null : 'pl-0'"
          :cols="12"
          :md="8">
          <v-card v-if="audit.statusId !== 3" class="mb-2">
            <v-card-text class="cursor-default">
              <div class="d-flex justify-space-between align-center w-100">
                <h2>Факти недоліку:</h2>
                <v-btn
                  :disabled="audit.statusId !== 2"
                  density="comfortable"
                  rounded="lg"
                  width="140px"
                  @click="createFlaw">
                  <v-icon class="mr-1">mdi-plus-circle</v-icon>
                  Факт недоліку
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
          <audit-flaw-list @take-to-work="takeToWork"></audit-flaw-list>
        </v-col>
        <v-col :cols="12" :md="12">
          <b-action-buttons
            v-if="audit.statusId !== 3"
            :confirm="submit"
            :disabled="!audit.$hasChanges"
            :reject="() => audit.$reset()"></b-action-buttons>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { cachedObject } from 'best-modules/utils'
import { computed, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios/index.js'
import {
  urlAuditSingle,
  urlAuditTakeToWork,
  urlAuditUpdate,
} from '@/utils/urls'
import { BActionButtons, BSectionLoader } from 'best-modules/components'
import { auditKey, updateAuditKey, v$Key } from './injectionKeys'
import { Audit } from '@/utils/types/audit'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useBreadcrumb } from '@/plugins/breadcrumb.js'
import { pick } from 'lodash'
import AuditSidebar from './AuditSidebar.vue'
import { openDialog } from '@/dialog'
import AuditFlawList from './AuditFlawList.vue'
import { setSnackbar } from 'best-modules/plugins/index'

export default {
  name: 'AuditSingle',
  components: {
    BSectionLoader,
    BActionButtons,
    AuditSidebar,
    AuditFlawList,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const breadcrumb = useBreadcrumb()
    const auditId = computed(() => route.params.id)
    const audit = cachedObject<Audit>({} as Audit)
    audit.$setIgnore(
      'flaws',
      'status',
      'statusId',
      'startCheckDate',
      'endCheckDate',
      'internalControlAssessmentId',
      'internal_control_assessment'
    )

    const updateAudit = (obj: Audit): void => {
      Object.assign(audit, obj)
    }
    const updateAuditStatus = (obj: Audit): void => {
      Object.assign(
        audit,
        pick(obj, [
          'status',
          'statusId',
          'startCheckDate',
          'endCheckDate',
          'internalControlAssessmentId',
          'internal_control_assessment',
        ])
      )
    }
    const getAudit = () => {
      return handleAsync('getAudit', () => {
        return axios
          .get(urlAuditSingle(auditId.value))
          .then(res => {
            audit.$set(res.data)
            breadcrumb.set([
              {
                index: 1,
                title: audit.name,
                disabled: true,
              },
            ])
          })
          .catch(() => {
            router.push({ name: 'audit-list ' })
          })
      })
    }

    const rules = {
      startAuditDate: { required: required },
      endAuditDate: { required: required },
      startScheduledCheckDate: { required: required },
      endScheduledCheckDate: { required: required },
    }
    const v$ = useVuelidate(rules, audit)

    provide(auditKey, audit)
    provide(updateAuditKey, updateAudit)
    provide(v$Key, v$)

    getAudit()

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return handleAsync('updateAudit', () => {
          return axios
            .post(urlAuditUpdate(auditId.value), audit.$object)
            .then(res => {
              audit.$set(res.data)
            })
        })
      }
    }

    const takeToWork = () => {
      return handleAsync('takeToWork', () => {
        return axios.get(urlAuditTakeToWork(audit.id)).then(res => {
          updateAuditStatus(res.data)
        })
      })
    }

    const completeAudit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        openDialog({
          name: 'AuditComplete',
          dialogData: { auditId: audit.id },
          params: {
            cb: res => updateAuditStatus(res),
          },
        })
      }
    }

    const createFlaw = () => {
      if (audit.statusId !== 2) {
        return setSnackbar({
          color: 'warning',
          text: 'Щоб додати факт недоліку потрібно взяти в роботу аудит',
        })
      }
      openDialog({
        name: 'AuditFlaw',
        action: 'create',
        dialogData: {
          auditId: audit.id,
        },
        params: {
          cb: flaw => {
            audit.flaws.push({
              ...flaw,
              expanded: false,
              activeOption: 'fact',
              recommendations: [],
            })
          },
        },
      })
    }

    return {
      v$,
      audit,
      auditId,
      submit,
      takeToWork,
      completeAudit,
      createFlaw,
    }
  },
}
</script>

<style lang="scss" scoped></style>
