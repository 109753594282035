<template>
  <v-layout height="100%">
    <b-sidebar-menu
      :items="menuItems"
      :loading="!user"
      :user="user"
      style="background: white">
      <template #append="{ isExpand }">
        <div style="height: 4rem" class="d-flex align-center pl-1">
          <RouterLink style="text-decoration: none" to="/eds">
            <new-logo-black v-if="isExpand" />
            <logo-small v-else />
          </RouterLink>
        </div>
      </template>
    </b-sidebar-menu>
    <the-header></the-header>
    <v-main style="background: #f2f7ff">
      <div class="page">
        <Breadcrumb class="py-2" />
        <div class="h-full">
          <RouterView :key="renderKey" />
        </div>
      </div>
    </v-main>
  </v-layout>
</template>

<script>
import TheHeader from '@/components/the-header/TheHeader.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useRoute } from '@/plugins'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { BSidebarMenu } from 'best-modules/components'
import { menuItems } from '@/router/menuItems.ts'
import NewLogoBlack from '@/components/icon/icons/black-new-logo.vue'
import LogoSmall from '@/components/icon/icons/logo-small.vue'

export default {
  components: {
    NewLogoBlack,
    LogoSmall,
    TheHeader,
    Breadcrumb,
    BSidebarMenu,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const user = computed(() => store.state.user.userData)

    store.dispatch('user/getUserData')

    const renderKey = computed(() => {
      const r = route.matched.find(r => r.meta.tabList) || route
      return `${r.name}-${route.params.id || 0}`
    })

    return { renderKey, menuItems, user }
  },
}
</script>

<style scoped>
@keyframes open {
  0% {
    display: none;
  }
  100% {
    display: inline-block;
  }
}
@keyframes close {
  0% {
    display: inline-block;
  }
  100% {
    display: none;
  }
}

.visible {
  animation-duration: 0.3s;
  animation-name: open;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
.hidden {
  animation-duration: 0.3s;
  animation-name: close;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}
</style>
