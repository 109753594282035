<template>
  <v-card elevation="1">
    <v-card-text>
      <div class="d-flex justify-end">
        <v-btn class="mb-3" @click="templateRoutingDocumentDialog('create')"
          >Створити шаблон
        </v-btn>
      </div>
      <b-server-table
        :key="tableRenderKey"
        :headers="templateRoutingListHeaders"
        :request-func="getTemplateDocuments">
        <template #bottom></template>
        <template #item.name="{ item }">
          {{ item?.name || '---' }}
        </template>
        <template #item.document_type="{ item }">
          {{ item.document_type?.name || 'Не визначено' }}
        </template>
        <template #item.actions="{ item }">
          <TableActions
            :actions="[
              {
                label: 'Редагувати шаблон',
                action: () => templateRoutingDocumentDialog('update', item),
                icon: 'mdi-pencil',
              },
              {
                label: 'Налаштувати маршрути',
                action: () => openRouteDialog('update', item),
                icon: 'mdi-pencil',
              },
              {
                label: 'Видалити',
                action: () => templateRoutingDocumentDelete(item.id),
                icon: 'mdi-delete',
              },
            ]" />
        </template>
      </b-server-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { ref } from 'vue'
import { axios } from '@/plugins'
import {
  urlDeleteTemplateRoutingDocument,
  urlGetTemplateRoutingDocuments,
} from '@/utils/urls'
import TableActions from '@/components/TableActions.vue'
import { navigateToItem } from '@/utils/helpers'
import { openDialog } from '@/dialog'
import { templateRoutingListHeaders } from '@/pages/audit/headers'
import { BServerTable } from 'best-modules/components/index'

export default {
  name: 'TemplateRoutingDocumentList',
  components: { TableActions, BServerTable },
  data: () => {
    return {
      templateRoutingListHeaders,
    }
  },
  methods: { navigateToItem },
  setup() {
    const tableRenderKey = ref(1)

    const openRouteDialog = (action, item) => {
      openDialog({
        name: 'TemplateGroup',
        action: 'update',
        dialogData: { templateId: item.id },
        params: {
          hideActionButtons: true,
        },
      })
    }

    const getTemplateDocuments = query => {
      return axios
        .get(urlGetTemplateRoutingDocuments({ ...query, paginate: true }))
        .then(res => {
          return res.data
        })
    }

    const templateRoutingDocumentDialog = (action, item) => {
      openDialog({
        name: 'TemplateRoutingDocument',
        action: action === 'update' ? 'update' : 'create',
        params: {
          title: `${action === 'update' ? 'Редагування' : 'Створення'} шаблону`,
          submitLabel: `${action === 'update' ? 'Змінити' : 'Створити'}`,
          cb: () => (tableRenderKey.value += 1),
        },
        dialogData: {
          documentData: action === 'update' ? item : {},
          isUpdateTemplate: action === 'update',
        },
      })
    }
    const templateRoutingDocumentDelete = id => {
      return axios.delete(urlDeleteTemplateRoutingDocument(id)).then(res => {
        tableRenderKey.value += 1
        return res
      })
    }

    return {
      getTemplateDocuments,
      templateRoutingDocumentDialog,
      templateRoutingDocumentDelete,
      openRouteDialog,
      tableRenderKey,
    }
  },
}
</script>

<style lang="scss" scoped></style>
